import logo from './sassylassyicon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>A sassy welcome from <a className="App-link"
          href="https://sassylassyevents.com" target="_blank"
          rel="noopener noreferrer"> Sassy Lassy Events!</a>
        </p>
      </header>
    </div>
  );
}

export default App;
